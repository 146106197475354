import { User } from '../../models/User';
import { GetFirstAndLastName } from '../../utils/user';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Block, Box } from '../../styles/BasicStyles';
import { Fight } from '../../models/Fight';
import { AnyObject } from '../../models/Generic';
import { LabelColor, PlaceholderColor, SubMenuColor } from '../../styles/Colors';
import UserAvatar from '../userAvatar';
import Typography from '../typography';
import Label from '../label';
import UserStatus from '../userStatus';

export type Size = 'xs' | 'sm' | undefined;

type UserProps = {
  user: User | AnyObject;
  fight?: Fight;
  size?: Size;
  noStatus?: boolean;
  showEmail?: boolean;
  borderColor?: string;
  center?: boolean;
  noUserText?: string;
};

const UserCard: FC<UserProps> = ({ user, fight, size = 'sm', noStatus = false, showEmail = false, borderColor, center = false, noUserText }) => {
  const { t } = useTranslation();

  return (
    <Box fJustify={center ? 'center' : 'flex-start'} fAlign='center' fWrap='wrap'>
      {
        user?._id ?
        <>
          <Block mr={0.75}>
            <UserAvatar user={user} size={size} borderColor={borderColor} />
          </Block>
          <Block display='flex' fDirection='column'>
            <Typography variant='table-header' style={{ color: LabelColor }}>{GetFirstAndLastName(user.name)}</Typography>
            { showEmail && <Typography variant='table-header' pt={0.15} style={{ color: PlaceholderColor }}>{user.email}</Typography> }
          </Block>
          {!noStatus && <UserStatus status={user.status} /> }
          {
            (
              (fight?.result?.winner === 'BLUE' && fight?.blue_fighter?._id === user._id) ||
              (fight?.result?.winner === 'RED' && fight?.red_fighter?._id === user._id)
            ) &&
            <Block ml={0.5}>
              <Label 
                text={t('WINNER').toUpperCase()} 
                fontColor='#ffffff' 
                bgColor='rgba(255, 255, 255, 0.25)' 
                bRadius='0.25rem' 
                size='xs' 
              />
            </Block>
          }
        </>
        :
        <Typography variant='table-header' style={{ color: SubMenuColor }}>{noUserText || 'TBA'}</Typography>
      }
    </Box>
  );
};

export default UserCard;
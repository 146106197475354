import { Event } from '../../models/Event';
import { AnyObject } from '../../models/Generic';
import { User } from '../../models/User';
import { Fight, ResultType } from '../../models/Fight';
import { Tournament } from '../../models/Tournament';
import { Club } from '../../models/Club';

export const GetUserInitialValues = (user: User) => ({
  ...user,
  federation: user.federation?._id
});

export const GetClubInitialValues = (club: Club) => ({
  ...club,
  federation: club.federation._id,
  country: club.country?._id,
  martial_arts: club.martial_arts?.map((elem: AnyObject) => elem._id)
});

export const GetOfficialInitialValues = (user: User) => ({
  ...user,
  federation: user.federation?._id,
  club: user.club?._id,
  country: user.country?._id,
  weight_class: user.weight_class?._id,
  class: user.class?.map((elem: AnyObject) => elem._id),
  martial_arts: user.martial_arts?.map((elem: AnyObject) => elem._id)
});

export const GetAthleteInitialValues = (user: User) => ({
  ...user,
  federation: user.federation?._id,
  club: user.club?._id,
  country: user.country?._id,
  weight_class: user.weight_class?._id,
  class: user.class?.map((elem: AnyObject) => elem._id),
  martial_arts: user.martial_arts?.map((elem: AnyObject) => elem._id),
  coach: user.coach?._id
});

export const GetCoachInitialValues = (user: User) => ({
  ...user,
  federation: user.federation?._id,
  club: user.club?._id,
  country: user.country?._id,
  class: user.class?.map((elem: AnyObject) => elem._id),
  martial_arts: user.martial_arts?.map((elem: AnyObject) => elem._id)
});

export const GetPromoterInitialValues = (user: User) => ({
  ...user,
  federation: user.federation?._id,
  country: user.country?._id
});

export const GetEventInitialValues = (event: Event) => ({
  ...event,
  country: event.country?._id,
  federation: event.federation?._id,
  promoter: event.promoter?._id || event.promoter
});

export const GetEventDraftInitialValues = (event: Event) => ({
  ...event,
  country: event.country?._id,
  federation: event.federation?._id,
  promoter: event.promoter?._id
});

export const GetFightInitialValues = (fight: Fight) => ({
  ...fight,
  blue_fighter: fight.blue_fighter?._id,
  red_fighter: fight.red_fighter?._id,
  tournament: fight.tournament?._id,
  weight_class: fight.weight_class?._id,
  class: fight.class?._id,
  referee: fight.referee?._id,
  referee2: fight.referee2?._id,
  white_judge: fight.white_judge?._id,
  green_judge: fight.green_judge?._id,
  yellow_judge: fight.yellow_judge?._id,
  record_keeper: fight.record_keeper?._id,
  commissioner: fight.commissioner?.map((elem: AnyObject) => elem._id),
  tournamentDetail: fight.tournament
});

export const GetTournamentInitialValues = (
  tournament?: Tournament | AnyObject
) => ({
  ...tournament
});

export const GetEditFightWinnerInitialValues = (result: ResultType) => ({
  ...result,
  submission: result?.submission?._id
});

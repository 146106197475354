import { UserStatusList } from "../../models/User";

export const GetFirstAndLastName = (name?: string) => {
  if(name?.includes(' ')) {
    const parts = name.split(' ');
    return `${parts[0]} ${parts[parts.length - 1]}`;
  }

  return name || '';
};

export const GetFirstAndLastInitials = (name?: string) => {
  if(name?.includes(' ')) {
    const parts = name.split(' ');
    return `${parts[0]?.charAt(0)}${parts[parts.length - 1]?.charAt(0)}`.toUpperCase();
  }
  
  return name?.charAt(0)?.toUpperCase() || '';
};

export const GetFirstInitial = (name?: string) => {
  return name?.charAt(0)?.toUpperCase() || '';
};

export const GetLastName = (name?: string) => {
  if(name?.includes(' ')) {
    const parts = name.split(' ');
    return parts[parts.length - 1];
  }

  return name || '';
};

export const GetUserStatus = (value: string | undefined) => UserStatusList.find(elem => elem._id === value) || {};

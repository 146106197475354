import { useTranslation } from 'react-i18next';
import { FC, useContext } from 'react';
import { PROMOTER, User } from '../../../../../models/User';
import { AnyObject } from '../../../../../models/Generic';
import { UserContext } from '../../../../../Context';
import Typography from '../../../../../components/typography';
import InfoCard, { Item } from '../../../common/InfoCard';
import dayjs from 'dayjs';

interface Params {
  athlete?: User;
  isDraftMember: boolean;
}

const InfoCards: FC<Params> = ({ athlete, isDraftMember }) => {
  const { t, i18n: { language } } = useTranslation();
  const { user } = useContext(UserContext);

  let martialArts = '';
  if(athlete?.martial_arts && athlete.martial_arts.length > 0) {
    athlete.martial_arts.forEach((martialArt: AnyObject) => martialArts = `${martialArts}${martialArt.name}, `);

    martialArts = martialArts.slice(0, -2); 
  }

  let evaluationClasses = '';
  if(athlete?.class && athlete.class.length > 0) {
    athlete.class.forEach((elem: AnyObject) => evaluationClasses = `${evaluationClasses}${elem.name[language]}, `);

    evaluationClasses = evaluationClasses.slice(0, -2); 
  }

  const generalItems = [
    {
      title: t('BIRTH_DATE'),
      value: athlete?.birthday ? dayjs(athlete?.birthday).format('DD MMM, YYYY') : ''
    },
    {
      title: t('COUNTRY'),
      value: athlete?.country?.name
    },
    {
      title: t('GENDER'),
      value: t(athlete?.gender || '')
    },
    {
      title: t('NATIONALITY'),
      value: athlete?.nationality
    },
    {
      title: t('WEIGHT_CLASS'),
      value: athlete?.weight_class?.name && athlete.weight_class.name[language]
    },
    {
      title: t('CLUB'),
      value: athlete?.club?.name
    }
  ];

  const federationItems: Item[] = [];
  let address = '', guardianAddress = '';

  // Promoters can't view certain fields
  if(user?.type !== PROMOTER) {
    if(athlete?.address) address = athlete.address;
    if(athlete?.postal_code) address = address ? `${address} ${athlete.postal_code}` : athlete.postal_code;
    if(athlete?.city) address = address ? `${address}, ${athlete.city}` : athlete.city;

    if(!!athlete?.guardian_name) {
      if(athlete?.guardian_address) guardianAddress = athlete.guardian_address;
      if(athlete?.guardian_postal_code) guardianAddress = address ? `${guardianAddress} ${athlete.guardian_postal_code}` : athlete.guardian_postal_code;
      if(athlete?.guardian_city) guardianAddress = guardianAddress ? `${guardianAddress}, ${athlete.guardian_city}` : athlete.guardian_city;
    }

    generalItems.splice(1, 0, {
      title: t('ADDRESS'),
      value: address
    });
    generalItems.splice(5, 0, {
      title: t('EMAIL'),
      value: athlete?.email
    });
    generalItems.splice(6, 0, {
      title: t('PHONE_NUMBER'),
      value: athlete?.phone
    });
    generalItems.splice(7, 0, {
      title: t('WEIGHT'),
      value: athlete?.weight && `${athlete.weight} kg`
    });
    generalItems.splice(8, 0, {
      title: t('HEIGHT'),
      value: athlete?.height && `${athlete.height} cm`
    });
    generalItems.splice(11, 0, {
      title: t('COACH'),
      value: athlete?.coach?.name
    });

    federationItems.splice(0, 0, {
      title: t('FEDERATION'),
      value: athlete?.federation?.name
    });
  }

  if(!isDraftMember) {
    federationItems.push({
      title: t('AFFILIATION_NUMBER'),
      value: athlete?.associate
    });
  }
  
  return (
    <>
      <InfoCard items={generalItems} />
      {
        user?.type !== PROMOTER && !!athlete?.guardian_name &&
        <>
          <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('GUARDIAN_INFO').toUpperCase()}</Typography>
          <InfoCard 
            items={[
              {
                title: t('GUARDIAN_NAME'),
                value: athlete?.guardian_name
              },
              {
                title: t('GUARDIAN_BIRTH_DATE'),
                value: athlete?.guardian_birthday ? dayjs(athlete?.guardian_birthday).format('DD MMM, YYYY') : ''
              },
              {
                title: t('GUARDIAN_PHONE_NUMBER'),
                value: athlete?.guardian_phone
              },
              {
                title: t('GUARDIAN_ADDRESS'),
                value: guardianAddress
              }
            ]}
          />
        </>
      }
      <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('CAREER_INFO').toUpperCase()}</Typography>
      <InfoCard 
        items={[
          {
            title: t('CLASS'),
            value: evaluationClasses
          },
          {
            title: t('MARTIAL_ARTS_BACKGROUND'),
            value: martialArts
          },
          {
            title: t('RECORD'),
            value: athlete?.record
          },
          {
            title: t('PROFILE_RECORD_LINK'),
            value: athlete?.profile_link,
            link: true
          }
        ]}
      />
      <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('FEDERATION_INFO').toUpperCase()}</Typography>
      <InfoCard items={federationItems} />
    </>
  );
};

export default InfoCards;

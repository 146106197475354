import * as yup from 'yup';
import { isAthleteRecord } from '../../../../hooks/use-validation/genericValidations';

export const validations = [
  yup.object({
    name: yup.string().required(),
    birthday: yup.date().required(),
    weight: yup.number().required(),
    height: yup.number().required(),
    gender: yup.string().required(),
    nationality: yup.string().required(),
    address: yup.string().required(),
    postal_code: yup.string().required(),
    city: yup.string().required(),
    country: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    weight_class: yup.string().required(),
    federation: yup.string().required(),
    record: isAthleteRecord
  }),
  yup.object({
    name: yup.string().required(),
    birthday: yup.date().required(),
    weight: yup.number().required(),
    height: yup.number().required(),
    gender: yup.string().required(),
    nationality: yup.string().required(),
    address: yup.string().required(),
    postal_code: yup.string().required(),
    city: yup.string().required(),
    country: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    weight_class: yup.string().required(),
    record: isAthleteRecord
  })
];

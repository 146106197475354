/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect } from 'react';
import { Tournament } from '../../../../../models/Tournament';
import { AnyObject } from '../../../../../models/Generic';
import { User } from '../../../../../models/User';
import { toggleAthlete } from './formHelpers';
import { useTranslation } from 'react-i18next';
import { showError } from '../../../../../hooks/show-notification/show-notification';
import Step1 from './Step1';
import Step2 from './Step2';

interface Params {
  eventStart?: any;
  eventEnd?: any;
  initialValues?: Tournament | AnyObject;
  setFightersCount: (value: number) => void;
  push: (name: string, value: AnyObject | null) => void;
  remove: (name: string, index: number) => void;
  fightersCount: number;
  athletes: User[];
  setAthletes: (list: User[]) => void;
  step: number;
}

const MainForm: FC<Params> = ({
  eventStart,
  eventEnd,
  initialValues,
  setFightersCount,
  push,
  remove,
  fightersCount,
  athletes,
  setAthletes,
  step
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    // Default: 2 fighters
    if(!initialValues?._id && fightersCount === 0) {
      for(let i = 0; i < 2; i++) push('fighters', null);

      setFightersCount(2);
    }
  }, []);

  // When selecting an Athlete, mark it as disabled. When removing a selected Athlete, mark it as enabled
  const checkAthlete = (value: string | null, oldValue: string | null) => {
    const aux = [...athletes];
    toggleAthlete(value, oldValue, athletes, aux);
    setAthletes(aux);
  };

  const addFighter = () => {
    push('fighters', null);
    setFightersCount(fightersCount + 1);
  }

  const removeFighter = (index: number) => {
    if(fightersCount > 2) {
      const ahtlete: any = remove('fighters', index);
      setFightersCount(fightersCount - 1);
      // Mark the Athlete as available again
      checkAthlete(null, ahtlete);
    }
    // Minimum of 2 fighters
    else {
      showError({
        title: t('ERROR'),
        message: t('MINIMUM_2_FIGHTERS')
      })
    }
  }

  return (
    <>
      {
        step === 1 ?
        <Step1 
          eventStart={eventStart}
          eventEnd={eventEnd}
          initialValues={initialValues}
        />
        :
        // We can only add select fighters when creating a Tournament.
        !initialValues?._id && step === 2 ?
        <Step2 
          athletes={athletes} 
          addFighter={addFighter}
          removeFighter={removeFighter}
          checkAthlete={checkAthlete}
        />
        :
        null
      }
    </>
  );
};

export default MainForm;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from '@mantine/core';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FC, useContext } from 'react';
import { ModalContent } from '../../../../../components/modal';
import { TournamentTypes } from '../../../../../utils/tournament/types';
import { Rounds, RoundsDuration } from '../../../../../utils/rounds';
import { InfoContext } from '../../../../../Context';
import { Block, Box } from '../../../../../styles/BasicStyles';
import { AnyObject } from '../../../../../models/Generic';
import { Tournament } from '../../../../../models/Tournament';
import { User } from '../../../../../models/User';
import { LabelColor } from '../../../../../styles/Colors';
import { GetFirstAndLastName } from '../../../../../utils/user';
import { OfficialLabel } from '../../../manage/styles';
import SelectInput from '../../../../../components/inputs/SelectInput';
import TextInput from '../../../../../components/inputs/TextInput';
import Divider from '../../../../../components/divider';
import DateTimeInput from '../../../../../components/inputs/DateTimeInput';
import UserAvatar from '../../../../../components/userAvatar';
import Typography from '../../../../../components/typography';

interface Params {
  eventStart?: any;
  eventEnd?: any;
  initialValues?: Tournament | AnyObject;
}

const Step1: FC<Params> = ({
  eventStart,
  eventEnd,
  initialValues
}) => {
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);

  return (
    <>
      <ModalContent>
        <Grid gutter="xl">
          <Grid.Col xs={12} sm={6}>
            <Field name="name">
              {(props) => (
                <TextInput
                  {...props}
                  label={`${t('NAME')} *`}
                  placeholder={t('ENTER_NAME')}
                />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Field name="type">
              {(props) => (
                <SelectInput
                  {...props}
                  label={`${t('TOURNAMENT_TYPE')} *`}
                  placeholder={t('CHOOSE_TYPE')}
                  data={TournamentTypes}
                  translate
                />
              )}
            </Field>
          </Grid.Col>
        </Grid>
        {!initialValues?._id && (
          <>
            <Grid gutter="xl">
              <Grid.Col xs={12} sm={6}>
                <Field name="weight_class">
                  {(props) => (
                    <SelectInput
                      {...props}
                      label={`${t('WEIGHT_CLASS')} *`}
                      placeholder={t('CHOOSE_CLASS')}
                      data={info?.weightClasses || []}
                      intl
                    />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <Field name="rounds">
                  {(props) => (
                    <SelectInput
                      {...props}
                      label={`${t('NUMBER_ROUNDS')} *`}
                      placeholder={t('CHOOSE_ROUNDS')}
                      data={Rounds}
                    />
                  )}
                </Field>
              </Grid.Col>
            </Grid>
            <Grid gutter="xl">
              <Grid.Col xs={12} sm={6}>
                <Field name="duration">
                  {(props) => (
                    <SelectInput
                      {...props}
                      label={`${t('ROUND_DURATION')} *`}
                      placeholder={t('CHOOSE_DURATION')}
                      data={RoundsDuration}
                    />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <Field name="class">
                  {(props) => (
                    <SelectInput
                      {...props}
                      label={`${t('CLASS')} *`}
                      placeholder={t('CHOOSE_CLASS')}
                      data={info?.evaluationClasses || []}
                      intl
                    />
                  )}
                </Field>
              </Grid.Col>
            </Grid>
          </>
        )}
      </ModalContent>
      <Divider />
      <ModalContent>
        <Grid gutter="xl">
          <Grid.Col xs={12} sm={6}>
            <Field name="start_datetime">
              {(props) => (
                <DateTimeInput 
                  {...props} 
                  label={t('START_DATE_TIME')}
                  minDate={new Date(eventStart)}
                  maxDate={new Date(eventEnd)}
                />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Field name="end_datetime">
              {(props) => (
                <DateTimeInput 
                  {...props} 
                  label={t('END_DATE_TIME')}
                  minDate={new Date(eventStart)}
                  maxDate={new Date(eventEnd)}
                />
              )}
            </Field>
          </Grid.Col>
        </Grid>
      </ModalContent>
      {
        !!initialValues?._id && initialValues?.fighters?.length > 0 &&
        <>
          <Divider />
          <ModalContent>
            <Typography variant='body-small' style={{ color: LabelColor }}>{t('SELECTED_FIGHTERS')}</Typography>
            <Block mt={1.25}>
              <Grid gutter="xl">
                {
                  initialValues.fighters.map((user: User, index: number) =>
                    <Grid.Col xs={12} sm={6} key={index} style={{ padding: '0.25rem 0.625rem' }}>
                      <Box fAlign='center'>
                        <OfficialLabel>
                          <UserAvatar user={user} size='xxs' firstInitial />
                          <Typography variant='sidebar-group' style={{ color: LabelColor }} pl={0.25} pr={0.375}>{GetFirstAndLastName(user?.name)}</Typography>
                        </OfficialLabel>
                      </Box>
                    </Grid.Col>
                  )
                }
              </Grid>
            </Block>
          </ModalContent>
        </>
      }
    </>
  );
};

export default Step1;

import * as yup from 'yup';
import { AnyObject } from '../../../../../models/Generic';
import { User } from '../../../../../models/User';
import { SortDisabledOfficials } from '../../../../../utils/user/officials';

interface Validations {
  step: number;
  schema: AnyObject;
}

const validations: Validations[]  = [
  {
    step: 1,
    schema: {
      create: 
        yup.object({
          name: yup.string().required(),
          type: yup.string().required(),
          weight_class: yup.string().required(),
          rounds: yup.string().required(),
          duration: yup.number().required(),
          class: yup.string().required()
        }),
      edit: 
        yup.object({
          name: yup.string().required(),
          type: yup.string().required()
        })
    },
  },
  {
    step: 2,
    schema: 
      yup.object({
        fighters: yup.array().of(
          yup.string().required()
        )
      })
  }
];

export const GetValidationSchema = (step: number, _id: string | null) => {
  const validation = validations.find((elem) => elem.step === step);

  if(!validation) return null;

  if(step > 1) return validation.schema;

  if(_id) return validation.schema.edit;

  return validation.schema.create;
};

export const toggleAthlete = (value: string | null, oldValue: string | null, athletes: User[], aux: User[], disableSorting = false) => {
  if(!!oldValue) {
    const index = athletes.findIndex((elem: User) => elem._id === oldValue);
    if(index >= 0) aux[index].disabled = false;
  }

  if(!!value) {
    const index = athletes.findIndex((elem: User) => elem._id === value);
    if(index >= 0) aux[index].disabled = true;
  }

  // Disabled items go to the end of the list per client request
  if(!disableSorting) aux = SortDisabledOfficials(aux);
};

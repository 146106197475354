import { User } from "../../models/User";

export const OfficialsExperience = [
  { _id: 'LEARNER', name: 'LEARNER' },
  { _id: 'BEGINNER', name: 'BEGINNER' },
  { _id: 'INTERMEDIATE', name: 'INTERMEDIATE' },
  { _id: 'ADVANCED', name: 'ADVANCED' },
  { _id: 'EXPERT', name: 'EXPERT' }
];

export const SortDisabledOfficials = (list: User[]) => {
  return list.sort((x, y) => x.disabled === y.disabled ? 0 : x.disabled ? 1 : -1);
};

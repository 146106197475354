import { Event } from './Event';
import { Tournament } from './Tournament';
import { User } from './User';
import { AnyObject } from './Generic';

export type Decision =
  | 'KO'
  | 'TKO'
  | 'UNANIMOUS_DECISION'
  | 'MAJORITY_DECISION'
  | 'SPLIT_DECISION'
  | 'UNANIMOUS_DRAW'
  | 'MAJORITY_DRAW'
  | 'SPLIT_DRAW'
  | 'NO_CONTEST'
  | 'TECHNICAL_DECISION'
  | 'TECHNICAL_DRAW'
  | 'OTHER'
  | 'SUBMISSION';

export const DecisionList = [
  { _id: 'KO', name: 'KO' },
  { _id: 'TKO', name: 'TKO' },
  { _id: 'UNANIMOUS_DECISION', name: 'UNANIMOUS_DECISION' },
  { _id: 'MAJORITY_DECISION', name: 'MAJORITY_DECISION' },
  { _id: 'SPLIT_DECISION', name: 'SPLIT_DECISION' },
  { _id: 'UNANIMOUS_DRAW', name: 'UNANIMOUS_DRAW' },
  { _id: 'MAJORITY_DRAW', name: 'MAJORITY_DRAW' },
  { _id: 'SPLIT_DRAW', name: 'SPLIT_DRAW' },
  { _id: 'NO_CONTEST', name: 'NO_CONTEST' },
  { _id: 'TECHNICAL_DECISION', name: 'TECHNICAL_DECISION' },
  { _id: 'TECHNICAL_DRAW', name: 'TECHNICAL_DRAW' },
  { _id: 'SUBMISSION', name: 'SUBMISSION' },
  { _id: 'OTHER', name: 'OTHER' }
];

export interface Submissions {
  readonly _id: string;
  name: AnyObject;
  type: string;
}

export interface ResultType {
  winner: 'RED' | 'BLUE' | 'DRAW';
  total: {
    red: number;
    blue: number;
  };
  decision?: Decision;
  submission?: Submissions | AnyObject;
  time: number;
  date?: string;
  round: number;
}

export interface Deduction {
  round: string;
  time: number;
  date: string;
  fighter: string;
  fighterName: string;
  points: number;
  reason: string;
  reasonName: string;
}

export interface Pause {
  round: string;
  time: number;
  start: number;
  startTime: string;
  duration: string;
  reason: string;
  reasonName: string;
}

export interface Fight {
  readonly _id: string;
  event: Event;
  tournament?: Tournament | AnyObject;
  tournament_round?: number;
  tournament_next_fight?: Fight;
  blue_fighter: User | AnyObject;
  red_fighter: User | AnyObject;
  weight_class: AnyObject;
  rounds: number;
  duration: number;
  class: AnyObject;
  start_datetime?: Date;
  end_datetime?: Date;
  referee: User | AnyObject;
  referee2: User | AnyObject;
  white_judge: User | AnyObject;
  green_judge: User | AnyObject;
  yellow_judge: User | AnyObject;
  record_keeper: User | AnyObject;
  commissioner?: User[];
  result?: ResultType;
  fight_number: number;
  order_priority: number;
  record?: AnyObject;
  consistency?: AnyObject;
  tournamentDetail?: Tournament | AnyObject;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import { Grid } from '@mantine/core';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FC, useContext } from 'react';
import {
  FocusColor,
  WhiteJudge,
  GreenJudge,
  YellowJudge
} from '../../../../../styles/Colors';
import {
  COMMISSIONER,
  JUDGE,
  PROMOTER,
  RECORD_KEEPER,
  REFEREE,
  User
} from '../../../../../models/User';
import { Rounds, RoundsDuration } from '../../../../../utils/rounds';
import { InfoContext, UserContext } from '../../../../../Context';
import { ModalContent } from '../../../../../components/modal';
import SelectInput from '../../../../../components/inputs/SelectInput';
import Typography from '../../../../../components/typography';
import Divider from '../../../../../components/divider';
import MultiSelectInput from '../../../../../components/inputs/MultiSelectInput';
import UserItem from '../../../../../components/userDropdownItem';
import DateTimeInput from '../../../../../components/inputs/DateTimeInput';

interface Params {
  athletes: User[];
  checkAthlete: (value: string | null, oldValue: string | null) => void;
  officials: User[];
  checkOfficial: (value: string | null, oldValue: string | null) => void;
  checkMultipleOfficials: (
    value: Array<string>,
    oldValue: Array<string>
  ) => void;
  fightNumber?: number;
  eventStart?: any;
  eventEnd?: any;
  isNew: boolean;
}

const FormFields: FC<Params> = ({
  athletes,
  checkAthlete,
  officials,
  checkOfficial,
  checkMultipleOfficials,
  fightNumber,
  eventStart,
  eventEnd,
  isNew
}) => {
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);

  return (
    <>
      <ModalContent>
        <Typography variant="body-small" fWeight={500} color={FocusColor}>{`${t('FIGHT').toUpperCase()} #${fightNumber}`}</Typography>
        <Grid gutter="xl">
          <Grid.Col xs={12} sm={6}>
            <Field name="red_fighter">
              {(props) => (
                <SelectInput
                  {...props}
                  label={`${t('RED_FIGHTER')}${isNew ? ' *' : ''}`}
                  placeholder={t('CHOOSE_FIGHTER')}
                  data={athletes}
                  afterChange={checkAthlete}
                  itemComponent={UserItem}
                  clearable={!isNew}
                />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Field name="blue_fighter">
              {(props) => (
                <SelectInput
                  {...props}
                  label={`${t('BLUE_FIGHTER')}${isNew ? ' *' : ''}`}
                  placeholder={t('CHOOSE_FIGHTER')}
                  data={athletes}
                  afterChange={checkAthlete}
                  itemComponent={UserItem}
                  clearable={!isNew}
                />
              )}
            </Field>
          </Grid.Col>
        </Grid>
        <Grid gutter="xl">
          <Grid.Col xs={12} sm={6}>
            <Field name="weight_class">
              {(props) => (
                <SelectInput
                  {...props}
                  label={`${t('WEIGHT_CLASS')} *`}
                  placeholder={t('CHOOSE_CLASS')}
                  data={info?.weightClasses || []}
                  intl
                />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Field name="rounds">
              {(props) => (
                <SelectInput
                  {...props}
                  label={`${t('NUMBER_ROUNDS')} *`}
                  placeholder={t('CHOOSE_ROUNDS')}
                  data={Rounds}
                />
              )}
            </Field>
          </Grid.Col>
        </Grid>
        <Grid gutter="xl">
          <Grid.Col xs={12} sm={6}>
            <Field name="duration">
              {(props) => (
                <SelectInput
                  {...props}
                  label={`${t('ROUND_DURATION')} *`}
                  placeholder={t('CHOOSE_DURATION')}
                  data={RoundsDuration}
                />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Field name="class">
              {(props) => (
                <SelectInput
                  {...props}
                  label={`${t('CLASS')} *`}
                  placeholder={t('CHOOSE_CLASS')}
                  data={info?.evaluationClasses || []}
                  intl
                />
              )}
            </Field>
          </Grid.Col>
        </Grid>
      </ModalContent>
      <Divider />
      <ModalContent>
        <Grid gutter="xl">
          <Grid.Col xs={12} sm={6}>
            <Field name="start_datetime">
              {(props) => (
                <DateTimeInput 
                  {...props} 
                  label={t('START_DATE_TIME')}
                  minDate={new Date(eventStart)}
                  maxDate={new Date(eventEnd)}
                />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Field name="end_datetime">
              {(props) => (
                <DateTimeInput 
                  {...props} 
                  label={t('END_DATE_TIME')}
                  minDate={new Date(eventStart)}
                  maxDate={new Date(eventEnd)}
                />
              )}
            </Field>
          </Grid.Col>
        </Grid>
      </ModalContent>
      {
        user?.type !== PROMOTER &&
        <>
          <Divider />
          <ModalContent>
            <Typography
              as="div"
              variant="body-small"
              fWeight={500}
              color={FocusColor}
              mb={1}
            >
              {t('OFFICIALS_LIST')}
            </Typography>
            <Grid gutter="xl">
              <Grid.Col xs={12} sm={6}>
                <Field name="white_judge">
                  {(props) => (
                    <SelectInput
                      {...props}
                      label={`${t('WHITE_JUDGE')} * `}
                      placeholder={t('CHOOSE_OFFICIAL')}
                      clearable
                      data={officials.filter(
                        (official: User) =>
                          official.roles?.length === 0 ||
                          official.roles?.includes(JUDGE)
                      )}
                      dotColor={WhiteJudge}
                      afterChange={checkOfficial}
                    />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <Field name="green_judge">
                  {(props) => (
                    <SelectInput
                      {...props}
                      label={`${t('GREEN_JUDGE')} * `}
                      placeholder={t('CHOOSE_OFFICIAL')}
                      clearable
                      data={officials.filter(
                        (official: User) =>
                          official.roles?.length === 0 ||
                          official.roles?.includes(JUDGE)
                      )}
                      dotColor={GreenJudge}
                      afterChange={checkOfficial}
                    />
                  )}
                </Field>
              </Grid.Col>
            </Grid>
            <Grid gutter="xl">
              <Grid.Col xs={12} sm={6}>
                <Field name="yellow_judge">
                  {(props) => (
                    <SelectInput
                      {...props}
                      label={`${t('YELLOW_JUDGE')} * `}
                      placeholder={t('CHOOSE_OFFICIAL')}
                      clearable
                      data={officials.filter(
                        (official: User) =>
                          official.roles?.length === 0 ||
                          official.roles?.includes(JUDGE)
                      )}
                      dotColor={YellowJudge}
                      afterChange={checkOfficial}
                    />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <Field name="record_keeper">
                  {(props) => (
                    <SelectInput
                      {...props}
                      label={`${t('RECORD_KEEPER')} * `}
                      placeholder={t('CHOOSE_OFFICIAL')}
                      clearable
                      data={officials.filter(
                        (official: User) =>
                          official.roles?.length === 0 ||
                          official.roles?.includes(RECORD_KEEPER)
                      )}
                      afterChange={checkOfficial}
                    />
                  )}
                </Field>
              </Grid.Col>
            </Grid>
            <Grid gutter="xl">
              <Grid.Col xs={12} sm={6}>
                <Field name="referee">
                  {(props) => (
                    <SelectInput
                      {...props}
                      label={`${t('REFEREE')} * `}
                      placeholder={t('CHOOSE_OFFICIAL')}
                      clearable
                      data={officials.filter(
                        (official: User) =>
                          official.roles?.length === 0 ||
                          official.roles?.includes(REFEREE)
                      )}
                      afterChange={checkOfficial}
                    />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <Field name="referee2">
                  {(props) => (
                    <SelectInput
                      {...props}
                      label={`${t('SECOND_REFEREE')} * `}
                      placeholder={t('CHOOSE_OFFICIAL')}
                      clearable
                      data={officials.filter(
                        (official: User) =>
                          official.roles?.length === 0 ||
                          official.roles?.includes(REFEREE)
                      )}
                      afterChange={checkOfficial}
                    />
                  )}
                </Field>
              </Grid.Col>
            </Grid>
            <Grid gutter="xl">
              <Grid.Col xs={12} sm={6}>
                <Field name="commissioner">
                  {(props) => (
                    <MultiSelectInput
                      {...props}
                      label={t('COMMISSIONER')}
                      placeholder={t('CHOOSE_OFFICIAL')}
                      clearable
                      data={officials.filter(
                        (official: User) =>
                          official.roles?.length === 0 ||
                          official.roles?.includes(COMMISSIONER)
                      )}
                      afterChange={checkMultipleOfficials}
                    />
                  )}
                </Field>
              </Grid.Col>
            </Grid>
          </ModalContent>
        </>
      }
    </>
  );
};

export default FormFields;

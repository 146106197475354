/* eslint-disable react/display-name */
import { Select, SelectProps } from '@mantine/core';
import { FieldRenderProps } from 'react-final-form';
import { AnyObject, GenericSelectInput } from './Generic';
import { useTranslation } from 'react-i18next';
import { InputWrapper, ErrorMessage, DotIcon } from './Styles';
import { DangerColor, LabelColor } from '../../styles/Colors';
import { InputSize } from './Generic';
import { FC, ReactNode } from 'react';
import Icon from '../icon';

export interface SelectInputProps extends Omit<FieldRenderProps<string>, 'input'>, Omit<SelectProps, 'data' | 'translate'> {
  input: GenericSelectInput<string>;
  afterChange?: (value: string | null, oldValue: string | null) => void;
  keyValue?: string;
  keyLabel?: string;
  label?: ReactNode;
  data: Array<AnyObject>;
  translate?: boolean;
  size?: InputSize;
  hideErrors?: boolean;
  dotColor?: string;
  intl?: boolean;
  isExternal?: boolean;
  showError?: boolean;
}

export const SelectInput: FC<SelectInputProps> = ({
  input,
  meta = {},
  label,
  placeholder,
  description,
  variant,
  afterChange,
  data = [],
  icon,
  disabled,
  keyValue = '_id',
  keyLabel = 'name',
  searchable = true,
  clearable = false,
  translate = false,
  intl = false,
  nothingFound,
  itemComponent,
  required,
  size = 'md',
  hideErrors = false,
  isExternal = false,
  dotColor,
  showError = false,
  ...rest
}) => {
  const { t, i18n: { language } } = useTranslation();

  const handleChange = (value: string | null): void => {
    const oldValue = input.value;

    input.onChange(value);
    if (afterChange) {
      afterChange(value, oldValue);
    }
  };

  const hasError = showError || (meta?.invalid && meta?.submitFailed);
  const rightSectionError = hasError ? <Icon icon='bold_xCircle' color={DangerColor} size={1.25} /> : '';
  
  return (
    <InputWrapper size={size} hasError={hasError && !hideErrors} isExternal={isExternal}>
      <Select
        {...rest}
        label={label}
        placeholder={placeholder}
        description={description}
        variant={variant}
        value={input.value}
        onChange={handleChange}
        error={hasError}
        searchable={searchable}
        clearable={clearable}
        disabled={disabled}
        icon={!!dotColor ? <DotIcon color={dotColor} /> : icon}
        nothingFound={nothingFound || t('NO_OPTIONS')}
        data={data.map((d: AnyObject) => ({
          value: d[keyValue],
          label: translate ? t(d[keyLabel]) : d[keyLabel] ? intl ? d[keyLabel][language] : d[keyLabel] : '',
          ...d,
          disabled: input.value === d[keyValue] ? false : d.disabled
        }))}
        itemComponent={itemComponent}
        required={required}
        rightSection={rightSectionError || (!input.value && <Icon icon='bold_caretDown' color={LabelColor} size={0.6} />)}
        styles={{ rightSection: { pointerEvents: 'none' } }}
        dropdownPosition='flip'
      />
      {
        hasError && !hideErrors &&
        <ErrorMessage>
          {t('FIELD_REQUIRED')}
        </ErrorMessage>
      }
    </InputWrapper>
  );
};

export default SelectInput;

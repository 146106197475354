/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { Tournament } from '../../models/Tournament';
import { Block } from '../../styles/BasicStyles';
import { capitalizeString } from '../../utils/strings';
import { LabelColor, PlaceholderColor } from '../../styles/Colors';
import { GetFightDate } from '../../utils/fights';
import { Event } from '../../models/Event';
import { canEditEvent } from '../../utils/events';
import { showSuccess } from '../../hooks/show-notification/show-notification';
import { ATHLETE, User } from '../../models/User';
import useFetch from 'use-http';
import headerActions, { HeaderBaseAction } from '../../components/header/HeaderActions';
import LoadingOverlay from '../../components/loadingOverlay';
import PageHeader from '../../components/page/PageHeader';
import Typography from '../../components/typography';
import ManageTournamentModal from '../events/detail/fightsModal/tournament';
import FightsList from './FightsList';
import Bracket from './Bracket';
import dayjs from 'dayjs';
import { HasFight } from '../../utils/tournament/single';
import { toggleAthlete } from '../events/detail/fightsModal/tournament/formHelpers';

const TournamentDetailPage = () => {
  const [loading, setLoading] = useState(true);
  const [reloadFightList, setReloadFightList] = useState<string>('');
  const [initialValues, setInitialValues] = useState<Tournament>();
  const [bracket, setBracket] = useState<any>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [event, setEvent] = useState<Event>();
  const [athletes, setAthletes] = useState<User[]>([]);
  const { t } = useTranslation();
  const { id } = useParams();
  const { get } = useFetch('/tournaments');
  const eventHooks = useFetch('/events');
  const navigate = useNavigate();
  const userCanEditEvent = canEditEvent(event);
  const { search } = useLocation();
  const athletesHook = useFetch(`/members/${ATHLETE}`);

  useEffect(() => {
    const init = async () => {
      await getInfo();
    };

    init();

    if(search.includes('success=true')) {
      showSuccess({
        title: t('SUCCESS'),
        message: t('TOURNAMENT_CREATED')
      });

      navigate(`/tournaments/${id}`, { replace: true });
    }
  }, [id]);

  const getInfo = async () => {
    if(id) {
      const tournamentResult = await get(`/${id}`);
      const bracketResult = await get(`/${id}/bracket`);

      if(tournamentResult?.success) {
        const eventResult = await eventHooks.get(`/${tournamentResult.data.event}`);
        
        setInitialValues(tournamentResult.data);
        setBracket(bracketResult?.data || []);
        setEvent(eventResult?.data);

        if(eventResult?.data?.federation?._id) {     
          const athletesResult = await athletesHook.get(`/${eventResult.data.federation._id}/dropdown`);
          const athletesList: User[] = [];

          if(athletesResult?.success && athletesResult?.data?.length > 0) {
            athletesResult.data.map((athlete: User) => {
              // Athletes that are in the tournament belong to group 1, the rest to group 2
              let group = t('FEDERATION');
              const index = tournamentResult.data?.fighters.findIndex((elem: User) => elem._id === athlete._id);
              if(index >= 0) group = t('IN_TOURNAMENT');

              athletesList.push({ 
                _id: athlete._id, 
                name: athlete.name, 
                status: athlete.status,
                disabled: HasFight(athlete._id, bracketResult?.data),
                group
              })
            });
          }

          setAthletes(athletesList);
        }
      }
      // If we don't have permission to see the event, return to homepage
      else {
        return navigate('/');
      }
    }

    setLoading(false);
  }

  const reloadInfo = async () => {
    setLoading(true);
    // Used to force fight list reload
    setReloadFightList(dayjs().format('YYYYMMDDHHmmss'));
    await getInfo();
  };

  const handleCloseModal = async (changed: boolean) => {
    if(changed) {
      setLoading(true);
      await getInfo();
    }
    setModalOpen(false);
  }

  // When selecting an Athlete, mark it as disabled. When removing a selected Athlete, mark it as enabled
  const checkAthlete = (value: string | null, oldValue: string | null) => {
    const aux = [...athletes];
    toggleAthlete(value, oldValue, athletes, aux, true);
    setAthletes(aux);
  };

  let actions: HeaderBaseAction[] = [];
  if(userCanEditEvent) actions = [
    {
      type: 'button',
      variant: 'primary',
      text: t('EDIT_TOURNAMENT'),
      onClick: () => setModalOpen(true)
    }
  ];

  return (
    <>
      <LoadingOverlay visible={loading} />
      <PageHeader
        title={
          <Block display='flex' fAlign='center' fWrap='wrap'>
            <Typography variant='page-title' style={{ color: LabelColor }} mr={1.25} w={{ xs: 'auto', xxs: '100%' }}>{capitalizeString(t('TOURNAMENT'))}</Typography>
            <Block display='flex' fDirection='column' w={{ xs: 'auto', xxs: '100%' }} pt={{ xs: 0, xxs: 0.5 }}>
              <Typography variant='body-small' lHeight={1.063} style={{ color: PlaceholderColor }}>{initialValues?.name}</Typography>
              {
                initialValues?.start_datetime && initialValues?.end_datetime &&
                <Typography variant='body-small' lHeight={1.063} style={{ color: PlaceholderColor }} pt={0.3}>
                  {`${GetFightDate(initialValues?.start_datetime)} - ${GetFightDate(initialValues?.end_datetime)}`}
                </Typography>
              }
            </Block>
          </Block>
        }
        backLink={-1}
        actions={headerActions({
          hasSave: false,
          extra: actions
        })}
      />
      <Bracket 
        tournament={initialValues}
        bracket={bracket} 
        athletes={athletes}
        reloadInfo={reloadInfo}
        checkAthlete={checkAthlete}
      />
      <Block w='100%' mt={3}>
        <FightsList 
          event={event}
          tournamentID={id}
          reloadOnChange={reloadFightList}
          bracket={bracket} 
          reloadInfo={reloadInfo}
        />
      </Block>
      <ManageTournamentModal
        opened={modalOpen}
        initialValues={initialValues}
        onClose={handleCloseModal}
        eventID={event?._id}
        eventFederation={event?.federation?._id}
      />
    </>
  );
};

export default TournamentDetailPage;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../../../../styles/BasicStyles';
import { useState, FC, useEffect, useContext } from 'react';
import { Fight } from '../../../../../models/Fight';
import { User, ATHLETE } from '../../../../../models/User';
import { showConfirm, showSuccess, showWarning } from '../../../../../hooks/show-notification/show-notification';
import { FocusColor } from '../../../../../styles/Colors';
import { UserContext } from '../../../../../Context';
import { GetValidationSchema } from './formHelpers';
import { SortDisabledOfficials } from '../../../../../utils/user/officials';
import Modal, { ModalActions, ModalOverflowContent } from '../../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../../components/button';
import FormFields from './FormFields';
import dayjs from 'dayjs';

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  initialValues?: Fight;
  eventOfficials?: User[];
  eventID?: string;
  eventStart?: any;
  eventEnd?: any;
  fightNumber?: number;
  eventFederation?: string;
  refreshPage?: () => void;
}

const { Form } = withTypes<Fight>();

const ManageSingleFightModal: FC<Params> = ({
  opened,
  onClose,
  initialValues,
  eventOfficials,
  eventID,
  fightNumber,
  eventStart,
  eventEnd,
  eventFederation,
  refreshPage
}) => {
  const { t } = useTranslation();
  const { post, put } = useFetch('/fights');
  const [athletes, setAthletes] = useState<User[]>([]);
  const [officials, setOfficials] = useState<User[]>([]);
  const [submitType, setSubmitType] = useState<number>(1);
  const athletesHook = useFetch(`/members/${ATHLETE}/${eventFederation}/dropdown`);
  const { user } = useContext(UserContext);

  useEffect(() => {
    getInfo();
  }, [opened]);

  const getInfo = async () => {
    const { data } = await athletesHook.get();

    if (eventOfficials && eventOfficials.length > 0) {
      let officialsList: User[] = [];
      const selectedOfficials = [
        initialValues?.referee?.toString(),
        initialValues?.referee2?.toString(),
        initialValues?.white_judge?.toString(),
        initialValues?.green_judge?.toString(),
        initialValues?.yellow_judge?.toString(),
        initialValues?.record_keeper?.toString()
      ];

      if (
        initialValues?.commissioner &&
        initialValues.commissioner.length > 0
      ) {
        initialValues.commissioner.forEach((value: User) => {
          selectedOfficials.push(value.toString());
        });
      }

      eventOfficials.map((official: User) =>
        officialsList.push({
          _id: official._id,
          name: official.name,
          roles: official.roles,
          disabled: selectedOfficials.includes(official._id) ? true : false
        })
      );

      // Disabled items go to the end of the list per client request
      officialsList = SortDisabledOfficials(officialsList);

      setOfficials(officialsList);
    }

    if (data?.length > 0) {
      let athletesList: User[] = [];
      const selectedAthletes = [
        initialValues?.blue_fighter?.toString(),
        initialValues?.red_fighter?.toString()
      ];

      data.map((athlete: User) => {
        const item: User = {
          _id: athlete._id,
          name: athlete.name,
          status: athlete.status,
          disabled: selectedAthletes.includes(athlete._id) ? true : false
        };

        if(!!initialValues?.tournament) {
          // Athletes that are in the tournament belong to group 1, the rest to group 2
          let group = t('FEDERATION');
          const index = initialValues.tournamentDetail?.fighters.findIndex((elem: string) => elem === athlete._id);
          if(index >= 0) group = t('IN_TOURNAMENT');

          item.group = group;
        }

        athletesList.push(item);
      });

      // Disabled items go to the end of the list per client request (except in Tournament because we have groups there)
      if(!initialValues?.tournament) athletesList = SortDisabledOfficials(athletesList);

      setAthletes(athletesList);
    }
  };

  const onSubmit = async (values: Fight) => {
    // check dates
    if(values.start_datetime && values.end_datetime) {
      const start = dayjs(values.start_datetime);
      const end = dayjs(values.end_datetime);

      if (start.isAfter(end) || end.isBefore(start) || start.isSame(end)) {
        return showWarning({
          title: t('WARNING'),
          message: t('WRONG_DATES_SELECTED')
        });
      }
    }

    let error = false;

    if(values._id) {
      const { success } = await put(`/${values._id}`, { ...values, event: eventID });
      if(success) onClose(true);
      else error = true;
    } 
    else {
      const { success } = await post({ ...values, event: eventID });
      if(success) {
        if(submitType === 1) onClose(true);
        else {
          showSuccess({
            title: t('SUCCESS'),
            message: t('FIGHT_CREATED_ADD_NEW')
          });

          getInfo();
          if(!!refreshPage) refreshPage();
        }
      }
      else error = true;
    }

    return !error;
  };

  // When selecting an Official, mark it as disabled. When removing a selected Official, mark it as enabled
  const checkOfficial = (value: string | null, oldValue: string | null) => {
    let aux = [...officials];

    if (!!oldValue) {
      const index = officials.findIndex((elem: User) => elem._id === oldValue);
      if (index >= 0) aux[index].disabled = false;
    }

    if (!!value) {
      const index = officials.findIndex((elem: User) => elem._id === value);
      if (index >= 0) aux[index].disabled = true;
    }

    // Disabled items go to the end of the list per client request
    aux = SortDisabledOfficials(aux);

    setOfficials(aux);
  };

  // Allow selecting multiple Commissioners
  const checkMultipleOfficials = (
    values: Array<string>,
    oldValues: Array<string>
  ) => {
    let aux = [...officials];

    // For each selected Official, mark it as disabled
    if (values?.length > 0) {
      values.forEach((value: string) => {
        const index = officials.findIndex((elem: User) => elem._id === value);
        if (index >= 0) aux[index].disabled = true;
      });
    }

    // For each Official that is in the oldValues array, but not in the values array, it means we have to enabled it
    if (oldValues?.length > 0) {
      const filtered = oldValues.filter(
        (value: string) => !values.includes(value)
      );

      if (filtered?.length > 0) {
        filtered.forEach((value: string) => {
          const index = officials.findIndex((elem: User) => elem._id === value);
          if (index >= 0) aux[index].disabled = false;
        });
      }
    }

    // Disabled items go to the end of the list per client request
    aux = SortDisabledOfficials(aux);

    setOfficials(aux);
  };

  // When selecting an Athlete, mark it as disabled. When removing a selected Athlete, mark it as enabled
  const checkAthlete = (value: string | null, oldValue: string | null) => {
    let aux = [...athletes];

    if (!!oldValue) {
      const index = athletes.findIndex(
        (athlete: User) => athlete._id === oldValue
      );
      if (index >= 0) aux[index].disabled = false;
    }

    if (!!value) {
      const index = athletes.findIndex(
        (athlete: User) => athlete._id === value
      );
      if (index >= 0) aux[index].disabled = true;
    }

    // Disabled items go to the end of the list per client request (except in Tournament because we have groups there)
    if(!initialValues?.tournament) aux = SortDisabledOfficials(aux);

    setAthletes(aux);
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(GetValidationSchema(initialValues?._id ? false : true, user?.type))}
      initialValues={initialValues || {}}
    >
      {({ handleSubmit, submitting, pristine, form: { reset } }) => {
        const handleClose = () => {
          if(!pristine) {
            showConfirm({
              title: t('EXIT_BEFORE_SAVE'),
              message: t('EXIT_BEFORE_SAVE_MESSAGE'),
              onConfirm: () => {
                onClose(false);
                reset();
              }
            });
          }
          else {
            onClose(false);
            reset();
          }
        };

        return (
          <Modal
            size={36.25}
            opened={opened}
            onClose={handleClose}
            title={initialValues && initialValues._id ? t('EDIT_FIGHT') : t('CREATE_FIGHT')}
          >
            <BasicForm onSubmit={async event => {
              const result = await handleSubmit(event);
              // If it's a new Fight, we need to force a empty array in "commissioner" field, otherwise it would maintain a value selected after the reset
              if(result) reset(initialValues || { commissioner: [] });
            }}>
              <ModalOverflowContent>
                <FormFields
                  athletes={athletes}
                  checkAthlete={checkAthlete}
                  officials={officials}
                  checkOfficial={checkOfficial}
                  checkMultipleOfficials={checkMultipleOfficials}
                  fightNumber={fightNumber}
                  eventStart={eventStart}
                  eventEnd={eventEnd}
                  isNew={initialValues?._id ? false : true}
                />
              </ModalOverflowContent>
              <ModalActions>
                <Button
                  text={t('CANCEL')}
                  variant="secondary"
                  onClick={handleClose}
                />
                {
                  !initialValues?._id &&
                  <Button
                    text={t('SAVE_ADD_NEW')}
                    variant={FocusColor}
                    type="submit"
                    loading={submitting}
                    disabled={pristine}
                    onClick={() => setSubmitType(2)}
                  />
                }
                <Button
                  text={initialValues?._id ? t('SAVE') : t('ADD_FIGHT')}
                  type="submit"
                  loading={submitting}
                  disabled={pristine}
                  onClick={() => setSubmitType(1)}
                />
              </ModalActions>
            </BasicForm>
          </Modal>
        );
      }}
    </Form>
  );
};

export default ManageSingleFightModal;

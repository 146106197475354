import { ATHLETE, User, DocumentType, OFFICIAL, JUDGE, RECORD_KEEPER, REFEREE, COMMISSIONER, COACH, PROMOTER } from '../../../models/User';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Box, BasicForm, Block } from '../../../styles/BasicStyles';
import { AlertErrorBg, DangerColor, DisabledColor, FocusColor, LabelColor, MenuBgColor, PlaceholderColor } from '../../../styles/Colors';
import { Federation } from '../../../models/Federation';
import { AnyObject } from '../../../models/Generic';
import { useNavigate } from 'react-router-dom';
import { LayoutWrapper } from '../../../components/layout/LayoutStyles';
import { FileLink } from '../Styles';
import { Field, withTypes } from 'react-final-form';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import { FieldArray } from 'react-final-form-arrays';
import { openFile } from '../../../utils/files';
import { useParams } from 'react-router';
import { CustomField } from '../../../models/CustomField';
import { GetFormValidations } from './formHelpers';
import { Club } from '../../../models/Club';
import { showError } from '../../../hooks/show-notification/show-notification';
import LoadingOverlay from '../../../components/loadingOverlay';
import useFetch from 'use-http';
import Icon from '../../../components/icon';
import Typography from '../../../components/typography';
import HeaderExternal from '../../../components/header/HeaderExternal';
import DateInput from '../../../components/inputs/DateInput';
import Button from '../../../components/button';
import arrayMutators from 'final-form-arrays';
import FormFields from './FormFields';
import toFormData from '../../../utils/formdata/ToFormData';
import dayjs from 'dayjs';

const { Form } = withTypes<User>();

const FormPage = () => {
  const [loading, setLoading] = useState(true);
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState<AnyObject[]>([]);
  const [federationInfo, setFederation] = useState<Federation>();
  const [customFields, setCustomFields] = useState<CustomField[]>();
  const [initialValues, setInitialValues] = useState<User>();
  const [clubs, setClubs] = useState<Club[]>([]);
  const { t, i18n: { language } } = useTranslation();
  const { type, federation, member } = useParams();
  const { get, post, put } = useFetch('/member-drafts');
  const federationsHook = useFetch('/federations/code');
  const customFieldsHook = useFetch(`/custom-fields/public/${type?.toUpperCase()}`);
  const clubsHooks = useFetch('/clubs');
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      const federationResult = await federationsHook.get(`/${federation}`);

      if(federationResult?.success) {
        const customFieldsResult = await customFieldsHook.get();

        setFederation(federationResult.data);
        setCustomFields(customFieldsResult?.data || []);
        
        // We only need to get the Clubs if the field is active for this Federation
        if(customFieldsResult?.data?.length > 0) {
          const clubField = customFieldsResult.data.find((elem: CustomField) => elem.name === 'CLUB');

          if(clubField) {
            const exists = federationResult.data.custom_fields.find((elem: { field: string, active: boolean }) => elem.field === clubField._id && elem.active === true);

            if(exists) {
              const clubsResult = await clubsHooks.get(`/getByFederation/${federationResult.data._id}`);
              setClubs(clubsResult?.data || []);
            }
          }
        }

        if(member) {
          const memberResult = await get(`/public/${member}`);

          if(memberResult?.success) {
            // If the current Member is not of the type / federation present in the URL, show invalid warning
            if(memberResult.data.type !== type?.toUpperCase() || memberResult.data.federation !== federationResult.data._id) {
              setInvalidUrl(true);
              setLoading(false);
              return false;
            }

            const files: AnyObject[] = [];

            loadSavedFiles(files, 'identification', memberResult.data.identification);
            loadSavedFiles(files, 'payment', memberResult.data.payment);
            loadSavedFiles(files, 'medical', memberResult.data.medical);
            loadSavedFiles(files, 'clinical', memberResult.data.clinical);
            loadSavedFiles(files, 'residence', memberResult.data.residence);
            loadSavedFiles(files, 'others', memberResult.data.others);
            loadSavedFiles(files, 'certification', memberResult.data.certification);
            loadSavedFiles(files, 'guardian_identification', memberResult.data.guardian_identification);
            loadSavedFiles(files, 'guardian_waiver', memberResult.data.guardian_waiver);

            setAcceptedFiles(files);
            setInitialValues({
              ...memberResult.data,
              identification_files: memberResult.data.identification,
              payment_files: memberResult.data.payment,
              medical_files: memberResult.data.medical,
              clinical_files: memberResult.data.clinical,
              residence_files: memberResult.data.residence,
              others_files: memberResult.data.others,
              certification_files: memberResult.data.certification,
              guardian_identification_files: memberResult.data.guardian_identification,
              guardian_waiver_files: memberResult.data.guardian_waiver
            });
          }
          // If no data was returned for the requested ID, then remove it from the URL (will be considered a new record)
          else {    
            navigate(`/external/${type}/${federation}`, { replace: true });
          }
        }
      }

      setLoading(false);
    };

    if(type?.toUpperCase() === ATHLETE || type?.toUpperCase() === OFFICIAL || type?.toUpperCase() === COACH || type?.toUpperCase() === PROMOTER) init();
    else {
      setInvalidUrl(true);
      setLoading(false);
    }
  }, [type, federation, member]);

  const loadSavedFiles = (files: AnyObject[], inputName: string, userFiles?: DocumentType[]) => {
    if(userFiles && userFiles.length > 0) {
      const inputFiles: AnyObject[] = [];

      userFiles.forEach((file: DocumentType) => {
        if(file.file) inputFiles.push(file.file);
      });

      files.push({
        inputName,
        files: inputFiles
      });
    }
  };

  const onSubmit = async (values: AnyObject) => {
    // TODO: find a way to validate the Guardian fields (only if they exist) in form validations, instead of doing it here
    // Athletes under 18 must fill in Guardian info
    if(type?.toUpperCase() === ATHLETE) {
      const subtract18 = dayjs().subtract(18, 'year');
      const isAfter = dayjs(values.birthday).isAfter(subtract18);

      if(
        isAfter && 
        (
          !values.guardian_name || 
          !values.guardian_birthday || 
          !values.guardian_address || 
          !values.guardian_postal_code || 
          !values.guardian_city || 
          !values.guardian_phone || 
          !values.guardian_identification || 
          !values.guardian_waiver
        )
      ) {
        return showError({
          title: t('ERROR'),
          message: t('MUST_FILL_GUARDIAN_INFO')
        });
      }
    }

    if(acceptedFiles?.length > 0) {
      acceptedFiles.forEach((input: AnyObject) => {
        const documents: AnyObject[] = [];

        if(input.files.length > 0) {
          input.files.forEach((file: AnyObject, fileIndex: number) => {
            documents.push({
              file,
              expiration_date: values[`${input.inputName}_files`] && values[`${input.inputName}_files`][fileIndex]?.expiration_date || ''
            });
          });

          values[input.inputName] = documents;
        }
      });
    }

    const roles = [];
    if(values.judge) roles.push(JUDGE);
    if(values.record_keeper) roles.push(RECORD_KEEPER);
    if(values.referee) roles.push(REFEREE);
    if(values.commissioner) roles.push(COMMISSIONER);
    values.roles = roles;

    const payload = toFormData({ ...values, type: type?.toUpperCase(), federation: federationInfo?._id, language: language || 'en' });

    let success = false;
    if(initialValues?._id) {
      ({ success } = await put(`/public/${initialValues._id}`, payload));
    }
    else {
      ({ success } = await post(payload));
    }

    if(success) return navigate('/external/success');
  };

  return (
    <>
      <LoadingOverlay visible={loading} />
      <HeaderExternal />
      <LayoutWrapper showSidebar={false}>
        <Box fDirection='column' maxW={43.75} margin='auto'>
          {
            federationInfo?._id && !invalidUrl &&
            <Box 
              fAlign='center' 
              fDirection='column' 
              bRadius={0.5} 
              style={{ backgroundColor: 'rgba(130, 138, 237, 0.2)' }} 
              padding='0.875' 
              mt={1.125} 
              mb={1}
            >
              <Typography fSize={1.25} lHeight={1.75} fWeight={600} as='h1' color='#ffffff' pb={0.25}>{t(`${type?.toUpperCase()}_PROFILE_FORM`)}</Typography>
              <Typography variant='body-small' as='h1' color={FocusColor}>{federationInfo?.name}</Typography>
            </Box>
          }
          <Form
            onSubmit={onSubmit}
            validate={useValidationSchema(GetFormValidations(customFields))}
            initialValues={initialValues || {}}
            mutators={{ ...arrayMutators }}
            render={({ handleSubmit, submitting, submitFailed, form: { mutators: { push } } }) => {
              const handleAcceptedFiles = (inputName: string | undefined, files: AnyObject[]) => {
                if(inputName) {
                  const index = acceptedFiles.findIndex(elem => elem.inputName === inputName);

                  if(index >= 0) {
                    const aux = [...acceptedFiles];
                    aux[index].files = [...acceptedFiles[index]?.files, ...files];
                    setAcceptedFiles(aux);
                  }
                  else {
                    setAcceptedFiles([ ...acceptedFiles, { inputName, files: [...files] } ]);
                  }

                  files.forEach(() => {
                    push(`${inputName}_files`, undefined);
                  });
                }
              };
    
              const removeFile = (inputName: string, index: number, fields: AnyObject) => {
                const inputIndex = acceptedFiles.findIndex(elem => elem.inputName === inputName);

                if(inputIndex >= 0) {
                  const aux = [...acceptedFiles];
                  aux[inputIndex].files.splice(index, 1);
                  setAcceptedFiles(aux);
      
                  fields.remove(index);
                }
              };

              const renderInputFiles = (inputName: string) => {
                const inputIndex = acceptedFiles.findIndex(elem => elem.inputName === inputName);

                return (
                  <>
                    {
                      acceptedFiles[inputIndex]?.files?.length > 0 &&
                      <Block w='100%' mb={1}>
                        <Typography as='div' variant='dropdown-menu-small' style={{ color: PlaceholderColor }} mt={1.125} mb={0.313}>{`${acceptedFiles[inputIndex].files.length} ${t('attachments')}`}</Typography>
                        <FieldArray name={`${inputName}_files`}>
                          {
                            ({ fields }) => 
                            fields.map((_, index) => (
                              <Box key={index} fAling='center' fWrap='wrap' style={{ marginRight: '-0.375rem', marginLeft: '-0.375rem' }} pb={0.75}>
                                <Block w={{ xs: '65%', xxs: '100%' }} pl={0.375} pr={0.375} pb={{ xs: 0, xxs: 0.375 }}>
                                  <Box bgColor={MenuBgColor} bRadius={0.25} fAlign='center' fJustify='space-between' padding='0.625 1'>
                                    <FileLink onClick={() => openFile(acceptedFiles[inputIndex].files[index])} >
                                      <Icon 
                                        icon='outlined_documentText' 
                                        color={FocusColor} 
                                      />
                                      <Typography 
                                        variant='dropdown-menu-small' 
                                        style={{ color: LabelColor }} 
                                        pl={1}
                                      >
                                        {acceptedFiles[inputIndex].files[index]?.blob?.name || acceptedFiles[inputIndex].files[index]?.filename}
                                      </Typography>
                                    </FileLink>
                                    <Icon 
                                      icon='bold_xCircle' 
                                      color={DisabledColor} 
                                      hoverColor={FocusColor} 
                                      onClick={() => removeFile(inputName, index, fields)} 
                                    />
                                  </Box>
                                </Block>
                                <Block w={{ xs: '35%', xxs: '100%' }} padding='0 0.375'>
                                  <Field name={`${inputName}_files[${index}].expiration_date`}>
                                    {(props) =>
                                      <DateInput
                                        {...props}
                                        placeholder={t('EXPIRATION_DATE')}
                                        clearable
                                      />
                                    }
                                  </Field>
                                </Block>
                              </Box>
                            ))
                          }
                        </FieldArray>
                      </Block>
                    }
                  </>
                );
              };

              return (
                <>
                  {
                    invalidUrl ?
                    <Box fDirection='column' maxW={43.75} margin='auto'>
                      <Box fDirection='column' fAlign='center' bRadius={0.5} bgColor='#222429' padding='2 1'>
                        <Icon icon='outlined_xCircle' size={4} color={AlertErrorBg} />
                        <Typography variant='body' fWeight={500} color='#ffffff' pt={1} tAlign='center'>{t('ERROR')}!</Typography>
                        <Typography variant='body-small' color='#ffffff' pt={0.5} maxW={25} tAlign='center'>{t('INVALID_FORM_URL')}</Typography>
                      </Box>
                    </Box>
                    :
                    federationInfo?._id ?
                    <BasicForm onSubmit={handleSubmit}>
                      <FormFields 
                        handleAcceptedFiles={handleAcceptedFiles}
                        renderInputFiles={renderInputFiles}
                        customFields={customFields}
                        federationInfo={federationInfo}
                        clubs={clubs}
                        initialValues={initialValues}
                      />
                      <Box fDirection='column' fAlign='center' mt={2.25} mb={4}>
                        <Button text={initialValues?._id ? t('SAVE') : t('SUBMIT_FORM')} type='submit' loading={submitting} />
                        {
                          submitFailed &&
                          <Typography variant='body-small' color={DangerColor} mt={1} tAlign='center'>{t('FORM_MISSING_FIELDS')}</Typography>
                        }
                      </Box>
                    </BasicForm>
                    :
                    !loading ?
                    <Box fDirection='column' maxW={43.75} margin='auto'>
                      <Box fDirection='column' fAlign='center' bRadius={0.5} bgColor='#222429' padding='2 1'>
                        <Icon icon='outlined_xCircle' size={4} color={AlertErrorBg} />
                        <Typography variant='body' fWeight={500} color='#ffffff' pt={1} tAlign='center'>{t('ERROR')}!</Typography>
                        <Typography variant='body-small' color='#ffffff' pt={0.5} maxW={25} tAlign='center'>{t('FEDERATION_NOT_FOUND')}</Typography>
                      </Box>
                    </Box>
                    :
                    null
                  }
                </>
              );
            }}
          />
        </Box>
      </LayoutWrapper>
    </>
  );
};

export default FormPage;

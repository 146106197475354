import * as yup from 'yup';

export const validations = [
  yup.object({
    name: yup.string().required(),
    birthday: yup.date().required(),
    gender: yup.string().required(),
    nationality: yup.string().required(),
    address: yup.string().required(),
    postal_code: yup.string().required(),
    city: yup.string().required(),
    country: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    federation: yup.string().required()
  }),
  yup.object({
    name: yup.string().required(),
    birthday: yup.date().required(),
    gender: yup.string().required(),
    nationality: yup.string().required(),
    address: yup.string().required(),
    postal_code: yup.string().required(),
    city: yup.string().required(),
    country: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required()
  })
];
